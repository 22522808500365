<!--
 * @Descripttion: new or edit layer about account
 * @version: 0.1
 * @Author: luoxi
 * @Date: 2019年12月24日19:50:28
 * @LastEditors: luoxi
 * @LastEditTime: 
 -->
<template>
  <Layer :layer="layer" width="400px" @submit="submit" top="calc(50vh - 118px)">
    <div class="center">
      <p class="red">确认要把所选文件放入回收站吗？</p>
      <p class="red">删除的文件可在10天内通过回收站还原</p>
    </div>
  </Layer>
  
</template>

<script>
  import { fileRemove, dirRemove} from '@/api/file'
  export default {
    inject:["reload"],
    components: {
      Layer: () => import('@/components/layer')
    },
    props: {
      layer: {
        type: Object,
        default: () => {
          return {
            show: false
          }
        }
      },
      rows: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data() {
      return {
        success: {
            file: false,
            dir: false
        }
      }
    },
    watch: {

    },
    created() {

    },
    mounted() {
      
    },
    methods: {
       
      // 总提交事件，用于跳转使用
      submit() {
        let fileList = [], dirList = []
        this.rows.filter(item => {
            if (item.type === 1) {
                fileList.push(item.id)
            } else {
                dirList.push(item.id)
            }
        })
        if (fileList.length > 0) {
            let params = {
                ids: fileList.join(',')
            }
            fileRemove(params)
            .then(res => {
                this.success.file = true
                this.commonHandle(res)
            })
        } else {
            this.success.file = true
        }
        if (dirList.length > 0) {
            let params = {
                ids: dirList.join(',')
            }
            dirRemove(params)
            .then(res => {
                this.success.dir = true
                this.commonHandle(res)
            })
        } else {
            this.success.dir = true
        }
      },
      // 公共处理函数，删除成功
      commonHandle(res) {
          this.$message.success(res.msg)
          if (this.success.file && this.success.dir) {
              this.$emit('success', this.rows)
              this.layer.show = false
              this.reload();
          }
      }
    }
  }
</script>

<style scoped="" lang="scss">
  .center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .red{
      font-size:16px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(248,102,89,1);
      padding-bottom: 5px;
    }
  }
</style>
