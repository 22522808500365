<!--
 * @Descripttion: 缩略图列表
 * @version: 0.1
 * @Author: luoxi
 * @Date: 
 * @LastEditors: lixianbing
 * @LastEditTime: 2020-3-24 15:14:13
 -->
<template>
    <div class="list">
        <div class="countss">
            <el-checkbox v-model="ches"></el-checkbox><span>文件（ {{countss}}个 ）</span>
        </div>
        <ul class="thumb_ul" ref="thumb" v-infinite-scroll="load">
            <li v-for="(item,key) in data" :key="key" @contextmenu="rightClick(item, $event)">
                <single :item="item"></single>
            </li>
        </ul>
        <right-menu v-model="showMenu" :position="position" :row="row" />
    </div>
</template>

<script>
    import {
        allList,
        typeList,
        dirRemove,
        fileRemove
    } from '@/api/file'
    import iconchange from '@/plugins/utils/iconchange'
    export default {
        components: {
            single: () => import('./single'),
            rightMenu: () => import('./menu')
        },
        model: {
            prop: 'choose',
            event: 'chooseChange'
        },
        props: {
            data: {
                type: Array,
                default: () => {
                    return []
                }
            },
            count: {
                type: Number,
                default: 0
            },
            choose: {
                type: Array,
                default: () => {
                    return []
                }
            },
            chooseData:{
                type:Object,
                defaultL: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                show: false,
                checkedGroup: [],
                unbind: false,
                listData: [],
                pagination: { // 分页
                    pageIndex: 1,
                    pageNums: 100,
                    count: 0
                },
                showMenu: false,
                position: {
                    x: 0,
                    y: 0
                },
                row: null,
                countss: "",
                ches: false
            }
        },
        watch: {
            ches(newVal) {
                for (const i in this.data) {
                    this.$set(this.data[i], 'choose', newVal)
                }
                if(newVal){
                    this.$emit('chooseChange', this.data)  
                }else{
                   this.$emit('chooseChange',this.ches)
                }
            },
            choose(newVal) {
                if (newVal.length === this.data.length) {
                    this.ches = true
                } else {
                    this.ches = false
                }
            }
        },
        created() {

        },
        mounted() {
            this.countss = this.count
            if (this.choose.length === this.data.length) {
                this.ches = true
            } else {
                this.ches=false
            }
            this.load();
        },
        methods: {
            // 选中项改变触发事件
            // handleSelectionChange(val) {
            //     this.$emit('chooseChange', val)
            // },
            // 右键点击事件
            rightClick(row, e) {
                this.showMenu = false
                this.position = {
                    x: e.clientX + document.documentElement.scrollLeft - document.documentElement.clientLeft,
                    y: e.clientY + document.documentElement.scrollTop - document.documentElement.clientTop
                }
                this.row = row
                e.preventDefault()
                e.stopPropagation()
                this.$nextTick(() => {
                    this.showMenu = true
                })
            },
            rightClass(row, rowIndex) {
                if (row.rightActive) {
                    return 'right-active'
                }
            },
            load() {
                let dom =this.$refs.thumb;
                dom.addEventListener('scroll', () => {
                    const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight
                    if (scrollDistance === 0) {
                        if (this.count !== this.data.length) {
                            this.$emit('getTableData')
                        }
                    }
                })
            },
        
        }
    }
</script>

<style scoped="" lang="scss">
    .list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: calc(100% - 45px);
        fiex:1;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-left: 21px;

        p {
            margin-left: 25px;
        }

        ul {
            width: 99.4%;
           height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
        }

    }

    /*滚动条样式*/
    ul::-webkit-scrollbar {
        width: 9px;
        height: 9px;
    }

    ul::-webkit-scrollbar-thumb {
        background: #bdbdbd;
        border-radius: 6px;
        transition: 0.2s;

        &:hover {
            background: #9A9A9AFF;
        }
    }

    ul::-webkit-scrollbar-track {}

    .countss {
        width: 100%;
        padding-left: 10px;
        line-height: 46px;
        font-size: 16px;
        color: #888888;

        span {
            display: inline-block;
            padding-left: 25px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #888888;
            padding-top: 1px;
        }

        &chechbox {
            padding-top: 1px;
        }
    }

    .thumb_ul {
        display: flex;
        flex-wrap: wrap;

        li {
            margin: 25px 15px;
        }
    }

    .check-box {
        width: 229px;
        text-align: center;
        position: absolute;
        z-index: 888;
        background-color: rgba(0, 0, 0, 0.2);

        span {
            display: inline-block;
            font-size: 16px;
            width: 24px;
            height: 24px;
            background: rgba(102, 120, 140, 1);
            border-radius: 50%;
            position: absolute;
            right: 9px;
            top: 6px;
            color: white;
        }

        .active {
            color: white;
            background: #09AAFF;
        }
    }
</style>
