/**
 * @description 根据后缀替换图标
 * @param {String} str 文件后缀
 */
const iconchange = function (str) {
    let icon = ''
    if (!str) {
      return '#filewenjianjia'
    }
    switch(str) {
        // word文档图标
        case 'doc':
            icon = '#fileword'
            break
        case 'dot':
            icon = '#fileword'
            break
        case 'docx':
            icon = '#fileword'
            break

        // 表格图标
        case 'xlsx':
            icon = '#file_BDYexcelwenjian'
            break
        case 'xls':
            icon = '#file_BDYexcelwenjian'
            break
        case 'xlt':
            icon = '#file_BDYexcelwenjian'
            break

        // ppt格式图标
        case 'ppt':
            icon = '#filePPTwenjian'
            break
        case 'pptx':
            icon = '#filePPTwenjian'
            break

        // 压缩文件图标
        case 'zip':
            icon = '#fileyasuobaowenjian'
            break
        case 'rar':
            icon = '#fileyasuobaowenjian'
            break
        case '7z':
            icon = '#fileyasuobaowenjian'
            break

        // html格式图标
        case 'html':
            icon = '#filehtml'
            break

        // 图片文件图标
        case 'png':
            icon = '#filetupian1'
            break
        case 'jpg':
            icon = '#filetupian1'
            break
        case 'gif':
            icon = '#filetupian1'
            break

        // 视频文件图标
        case 'mp4':
            icon = '#fileicon-wenjianxitong-'
            break
        case 'ogg':
            icon = '#fileicon-wenjianxitong-'
            break
        case 'avi':
            icon = '#fileicon-wenjianxitong-'
            break
        case 'flv':
            icon = '#fileicon-wenjianxitong-'
            break
        case 'mov':
            icon = '#fileicon-wenjianxitong-'
            break
        case 'mpeg':
            icon = '#fileicon-wenjianxitong-'
            break

        // 音频文件图标
        case 'mp3':
            icon = '#fileyinpinwenjian'
            break
        case 'wav':
            icon = '#fileyinpinwenjian'
            break
        case 'mid':
            icon = '#fileyinpinwenjian'
            break
        case 'wma':
            icon = '#fileyinpinwenjian'
            break
        case 'ra':
            icon = '#fileyinpinwenjian'
            break
        case 'ape':
            icon = '#fileyinpinwenjian'
            break
        case 'cda':
            icon = '#fileyinpinwenjian'
            break

        // txt格式图标
        case 'txt':
            icon = '#filetxt'
            break

        // 默认图标
        default:
            icon = '#fileqitawenjian1'
    }
    return icon
}
export default iconchange;
