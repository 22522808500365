/**
 * @description 转换成合适的单位返回进行显示
 * @param {String} num 以B为单位的数据
 */
const sizechange = function(num) {
  let string = ''
  if (!num) {
    return string
  }
  num = parseInt(num)
  if (num < 1024) {
    string = num + 'B'
  } else if (1024 <= num && num < 1024 ** 2) {
    string = (num/(1024 ** 1)).toFixed(2) + 'KB'
  } else if (1024 ** 2 <= num && num < 1024 ** 3) {
    string = (num/(1024 ** 2)).toFixed(2) + 'MB'
  } else if (1024 ** 3 <= num && num < 1024 ** 4) {
    string = (num/(1024 ** 3)).toFixed(2) + 'GB'
  } else if (1024 ** 4 <= num && num < 1024 ** 5) {
    string = (num/(1024 ** 4)).toFixed(2) + 'TB'
  } else if (1024 ** 5 <= num && num < 1024 ** 6) {
    string = (num/(1024 ** 5)).toFixed(2) + 'PB'
  } else {
    string = '数据异常'
  }
  return string
}

export default sizechange