<!--
 * @Descripttion: 表格
 * @version: 0.1
 * @Author: luoxi
 * @Date: 2020年3月19日9:30:25
 * @LastEditors: luoxi
 * @LastEditTime: 2020年3月23日14:55:49
 -->
<template>
    <div class="table-father">
        <el-table
          ref="table"
          height="100%"
          highlight-current-row
          :data="data"
          @selection-change="handleSelectionChange"
          @row-contextmenu="rightClick"
        >
            <el-table-column type="selection" width="60">
            </el-table-column>
            <el-table-column label="文件" min-width="200">
                <template slot="header">
                    <span class="title">文件（ {{ count }}个 ）</span>
                </template>
                <template slot-scope="scope">
                    <div class="file_name">
                      <div class="file">
                          <svg class="iconfont table-svg" aria-hidden="true">
                              <use :xlink:href="scope.row.icon"></use>
                          </svg>
                          <div @click="leftClick(scope.row, $event)">
                          <p class="name" :title="scope.row.name" v-if="!scope.row.showRename">{{ scope.row.name }}</p>
                          </div>
                          <rename
                            v-if="scope.row.showRename"
                            :row="scope.row"
                            @close="closeRename"
                            @submit="submitRename"
                          />
                      </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="格式" width="170">
                <template slot-scope="scope">
                    <template v-if="scope.row.fileExt">
                        {{ scope.row.fileExt }}
                    </template>
                    <template v-else>
                        --
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="address" label="大小" width="170">
                <template slot-scope="scope">
                    <template v-if="scope.row.size">
                        {{ scope.row.sizeString }}
                    </template>
                    <template v-else>
                        --
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="dateTime" label="修改日期" width="250">
            </el-table-column>
        </el-table>
        <right-menu
          v-model="showMenu"
          :position="position"
          :row="row"
        />
        <img-preview :layer="layer" :list="data" />
        <video-preview :layer="videoLayer" />
       
    </div>
</template>

<script>
  export default {
    components: {
      rightMenu: () => import('./menu'), // 右键菜单
      rename: () => import('./rename'), // 重命名
      imgPreview: () => import('@/components/preview/img'), // 图片预览功能
      videoPreview: () => import('@/components/preview/video') // 图片预览功能
    },
    model: {
      prop: 'choose',
      event: 'chooseChange'
    },
    props: {
      data: {
        type: Array,
        default: () => {
          return []
        }
      },
      count: {
        type: Number,
        default: 0
      },
      choose: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data() {
      return {
        showMenu: false, // 菜单显隐控制器
        position: {      // 菜单位置控制器
          x: 0,
          y: 0
        },
        row: null,
        layer: {      // 图片预览专用
          show: false,
          active: {},
          position: {
            x: 0,
            y: 0
          }
        },
        videoLayer: {
          show: false,
          active: {}
        }
      }
    },
    watch: {

    },
    created() {

    },
    mounted() {
      this.initChooseData()
      this.loadMore()
      // console.log(this.data[0].fileType)
    },
    methods: {
      // 初始化选中项
      initChooseData() {
        this.$nextTick(()=>{
          this.data.forEach(row => {
            if (this.choose.includes(row)) {
              this.$refs.table.toggleRowSelection(row, true)
            }
          })
        })
      },
      // 选中项改变触发事件
      handleSelectionChange(val) {
        this.$emit('chooseChange', val)
      },
      // 左键点击事件
      leftClick(row, e) {
        // 判断是文件点击还是文件夹点击
        if (row.fileType) {
          this.showThumb(row, e)
        } else {
          this.dirJump(row)
        }
      },
      // 路由跳转
      dirJump(row) {
        this.$store.commit('file/navPush', row)
      },
      // 打开预览功能
      showThumb(row, e) {
        if (row.fileType === 1) {
          this.layer.active = row
          this.layer.position = {
            x: e.clientX + document.documentElement.scrollLeft - document.documentElement.clientLeft,
            y: e.clientY + document.documentElement.scrollTop  - document.documentElement.clientTop
          }
          this.layer.show = true
        } else if (row.fileType === 2) {
          this.videoLayer.active = row
          this.videoLayer.show = false
        }
         else {
          window.open(row.fileDownloadUrl)
        }
      },
      // 右键点击事件
      rightClick(row, column, e) {
        this.$refs.table.setCurrentRow(row)
        this.showMenu = false
        this.position = {
          x: e.clientX + document.documentElement.scrollLeft - document.documentElement.clientLeft,
          y: e.clientY + document.documentElement.scrollTop  - document.documentElement.clientTop
        }
        this.row = row
        e.preventDefault()
        e.stopPropagation()
        this.$nextTick(() => {
          this.showMenu = true
        })
      },
      // 新增成功关闭事件,供rename组件使用
      submitRename(row) {
        row.showRename = false
      },
      // 关闭事件,供rename组件使用
      closeRename(row) {
        // 判断新建的文件夹还是重命名文件夹
        if (row.id) {
          row.showRename = false
        } else {
          // 调用取消新建文件夹事件
          this.$emit('cancelNewDir')
        }
      },
      // 无限加载事件
      loadMore() {
        let dom = this.$refs.table.$el.querySelector('.el-table__body-wrapper')
        dom.addEventListener('scroll', () => {
          const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight
          if (scrollDistance === 0) {
            if (this.count !== this.data.length) {
              this.$emit('getTableData')
            }
          }
        })
      }
    }
  }
</script>

<style scoped="" lang="scss">
  .table-father{
    height: 100%;
    // display: flex;
    // flex-direction: column;
  }
    // 表格样式初始化
    .el-table{
      width: calc(100% - 10px);
      /deep/ td, /deep/ th.is-leaf{
          border-bottom: 0;
      }
      /deep/ .el-table-column--selection{
        padding-left: 21px;
      }
      /deep/ .el-table__body-wrapper{
        position: relative;
        &::-webkit-scrollbar{
          width: 9px;
          height: 9px;
        }
        &::-webkit-scrollbar-thumb{
          background: #bdbdbd;
          border-radius: 6px;
          transition: 0.2s;
          &:hover{
            background: #9A9A9AFF;
          }
        }
        &::-webkit-scrollbar-track{
          
        }
      }
    }
    .el-table::before{
        background-color: transparent;
    }
    
    /deep/ .el-table td, .el-table th{
        padding: 0;
    }
    .file{
        display: flex;
        align-items: center;
        cursor: pointer;
        .rename{
          padding: 5px 0;
          margin-left: 10px;
        }
        p{
          margin: 11px 0;
          margin-left: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          overflow: hidden;
          -webkit-box-orient: vertical;
          height: 33px;
          line-height: 33px;
        }
    }
    .table-svg{
        width: 25px;
        height: 25px;
        
    }
    .title{
      font-size:16px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(136,136,136,1);
    }
</style>
