<!--
 * @Descripttion: 
 * @version: 0.1
 * @Author: luoxi
 * @Date: 
 * @LastEditors: luoxi
 * @LastEditTime: 
 -->
<template>
    <div class="home">
        <!-- 顶部模块 -->
        <div class="topbtn">
            <div class="btn-box">
                <div>
                    <el-button type="primary" v-show="type === 0" @click="newDir"><i class="iconfont filexinjian"></i>新建文件夹</el-button>
                    <el-button @click="delRow" :type="chooseData.length > 0 ? 'danger' : 'primary'"><i class="iconfont filedel"></i>删除</el-button>
                </div>
            </div>
            <circle-radio v-model="sortType" :options="list" />
            <div class="changes">
                <div>
                    <el-button type="primary"><i class="el-icon-upload2"></i>上传</el-button>
                    <!-- <el-button><i class="el-icon-download"></i>下载</el-button> -->
                    <el-button type="primary" @click="changeMode"><i class="iconfont filemoshiqiehuan"></i>转换列表样式</el-button>
                </div>
            </div>
        </div>
        <!-- 面包屑导航 -->
        <el-breadcrumb separator-class="el-icon-arrow-right" class="nav" v-if="type === 0">
            <el-breadcrumb-item v-for="(item, key) in fileNav" :key="key">
                <!-- v-if用于优化点击事件，防止可以一直触发点击事件 -->
                <a @click="changeNav(item)" v-if="key !== fileNav.length - 1">{{ item.name }}</a>
                <span v-if="key === fileNav.length - 1">{{ item.name }}</span>
            </el-breadcrumb-item>
        </el-breadcrumb>
        <div class="model">
            <!-- 表格模式 -->
            <Table
                ref="table"
                v-if="mode === 1"
                v-model="chooseData"
                :data="tableData"
                :count="pagination.count"
                :resData='resData'
                @cancelNewDir="cancelNewDir"
                @getTableData="getTableData"
            />
            <!-- 缩略图模式 -->
            <themlist
                v-if="mode === 2"
                v-model="chooseData"
                :data="tableData"
                :count="pagination.count"
                @cancelNewDir="cancelNewDir"
                @getTableData="getTableData"
            />
        </div>
        <div class="mg-site-recycle" title="回收站" @click="jumpRecycle"><i class="iconfont filehuishouzhan"></i></div>
        <delLayer :layer="delLayer" v-if="delLayer.show" :rows="chooseData"/>
    </div>
</template>

<script>
    import themlist from "./list" // 缩略图列表
    import Table from './table' // 表格列表
    import delLayer from "./layer/del"
    import {
        allList,
        typeList,
        dirRemove,
        fileRemove
    } from '@/api/file' // 接口导入
    import iconchange from '@/plugins/utils/iconchange' // 根据 fileExt 生成 icon
    import sizechange from '@/plugins/utils/sizechange' // 根据 size 生成 sizeString
    export default {
        components: {
            circleRadio: () => import('@/components/circle-radio'),
            themlist,
            Table,
            delLayer
        },
        computed: {
            name() {
                return this.$store.state.header.input
            },
            fileNav() {
                return this.$store.state.file.nav
            }
        },
        data() {
            return {
                sortType: 1,
                resData:'',
                list: [{
                        id: 1,
                        label: '名称'
                    },
                    {
                        id: 2,
                        label: '日期'
                    },
                    {
                        id: 3,
                        label: '大小'
                    }
                ],
                mode: 1, // 表格和缩略图模式切换使用
                tableData: [], // 后端获取的数据
                chooseData: [], // 已选中项
                pagination: { // 分页
                    pageIndex: 1,
                    pageNums: 100,
                    count: 0
                },
                type: 0, // 路由类型
                delLayer: {
                  show: false,
                  title: '确认删除',
                  showButtons: true
                },
            }
        },
        watch: {
            // 路由变化
            '$route.matched': {
                handler(val) {
                    this.type = val[val.length - 1].meta.type
                    this.getTableData(true)
                },
                immediate: true
            },
            // 排序类型变化
            sortType(newVal) {
                this.getTableData(true)
            },
            // 名称变化
            name(newVal) {
                this.getTableData(true)
            },
            fileNav(newVal) {
                this.getTableData(true)
            }
        },
        created() {
            // this.getTableData(true)
        },
        mounted() {
            
        },
        methods: {
            // 获取列表数据
            getTableData(init) {
                if (init) {
                    this.pagination.pageIndex = 1
                }
                let params = {
                    pageIndex: this.pagination.pageIndex,
                    pageNums: this.pagination.pageNums,
                    sortType: this.sortType,
                    name: this.name
                }
                if (this.type === 0) {
                    if (this.fileNav.length > 1) {
                        params.dirId = this.fileNav[this.fileNav.length - 1].id
                    }
                    allList(params)
                        .then(res => {
                            this.dataHandling(res)
                        })
                } else {
                    params.fileType = this.type
                    typeList(params)
                        .then(res => {
                            this.dataHandling(res)
                        })
                }
            },
            // 数据转换处理
            dataHandling(res) {
                let data = res.data.data
              
                for (const i in data) {
                    data[i].icon = iconchange(data[i].fileExt)
                    data[i].sizeString = sizechange(data[i].size)
                    data[i].showRename = false
                }
                if (this.pagination.pageIndex === 1) {
                    this.tableData = data
                } else {
                    this.tableData = this.tableData.concat(data)
                }
                this.pagination.count = res.data.count
                this.pagination.pageIndex += 1
            },
            // 切换视图
            changeMode() {
                if (this.mode === 1) {
                    this.mode = 2
                } else {
                    this.mode = 1
                }
            },
            delRow() {
                if(this.chooseData.length<=0){
                    this.$message.error("请先选择数据")
                }else{
                    this.delLayer.row = this.row
                    this.delLayer.show = true
                }
            },
            // 跳转至回收站
            jumpRecycle() {
                this.$router.push({
                    name: 'recovery'
                })
            },
            // 新建文件夹
            newDir() {
                this.pagination.count += 1
                if (this.$refs.table.row) {
                    this.$refs.table.row.showRename = false
                }
                this.tableData.unshift({
                    name: '',
                    showRename: true,
                    type: 2,
                    icon: iconchange(''),
                    dateTime: this.$options.filters['datetime'](new Date()) // 使用过滤器改变数据
                })
            },
            // 取消新建文件夹
            cancelNewDir() {
                this.pagination.count -= 1
                this.tableData.shift(0, 1)
            },
            // 点击面包屑导航时修改文件路径
            changeNav(item) {
                this.$store.commit('file/navChange', item)
            }
        }
    }
</script>

<style scoped="" lang="scss">
    .home {
        background: white;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 6px 0px rgba(43, 43, 43, 0.05);
        border-radius: 6px;
        height: 100%;
        display: flex;
        flex-direction: column;
        .btn-box {
            .el-button {
                margin-right: 10px;
                margin-left: 0px;
            }
        }

        .nav {
            margin-left: 30px;
            margin-bottom: 30px;
        }

        .model {
            padding-bottom: 30px;
            height: calc(100% - 177px);
            flex: 1;
        }
    }

    .topbtn {
        display: flex;
        justify-content: space-between;
        padding: 30px 30px;
        flex-wrap: wrap;
        align-items: center;
    }

    .tablist {
        margin-left: 24px;
    }

    i {
        padding-right: 5px;
        box-sizing: content-box;
    }

    .mg-site-recycle {
        position: fixed;
        right: 110px;
        bottom: 97px;
        width: 70px;
        height: 70px;
        cursor: pointer;
        border-radius: 80px;
        background: #f86659;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        line-height: 40px;
        background-size: 40px 40px;
        transition: 0.2s;
        opacity: 0.8;
        transition: 0.5s;
        &:hover {
            background-color: red;
            opacity: 1;
        }
        i {
            font-size: 25px;
            padding-right: 0px;
            padding-left: 1px;
        }
    }

    .el-icon-upload2,
    .el-icon-download {
        font-size: 16px;
    }
</style>
